import api from "@/base/utils/request";

// 获取架构树状结构数据
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data
  });
};

// 保存职务架构
export const savePositionLevel = data => {
  return api({
    url: "/admin/cyc/position/savePositionLevel",
    method: "post",
    data
  });
};