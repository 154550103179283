<template>
  <div class="level-config" v-loading="loading">
    <Reminder :textArr="tenantVersion === 'school' ? schoolReminderText: reminderText" />
    <el-tabs v-model="activeName" @tab-click="handleClick" v-if="positionLevels.length">
      <el-tab-pane v-for="(tab, index) in positionLevels" :label="tab.tab_name" :name="tab.id" :key="index">
      </el-tab-pane>
    </el-tabs>
    <el-tree style="margin-top: 32px;" :expand-on-click-node="false" :data="positionList" node-key="id"
      :props="treeProps" default-expand-all @node-drag-start="handleDragStart" @node-drag-end="handleDragEnd"
      draggable :allow-drag="allowDrag" :allow-drop="allowDrop">
    </el-tree>
  </div>
</template>

<script>
import Reminder from "@/modules/common/components/Reminder.vue";
import {
  positionLevel,
  savePositionLevel,
} from "../../api/meeting-post/level-config";
export default {
  components: { Reminder },
  data() {
    return {
      loading: false,
      saveLoading: false,
      reminderText: [
        "在此配置同层级的会内职务等级顺序",
        "组织架构的申请状态为“允许用户提交申请”才会有预备会员职务显示，预备会员职务无法进行等级顺序调整",
      ],
      schoolReminderText: [
        "在此配置同层级的会内职务等级顺序",
        "校友会的申请状态为“开启认证申请”才会有预备会员职务显示，预备会员职务无法进行等级顺序调整",
      ],
      positionLevels: [],
      positionList: [],
      activeName: "",
      treeProps: {
        label: "name",
        children: "child",
      },
    };
  },
  created() {
    this.getPositionLevel();
  },
  methods: {
    // 获取架构树状结构
    getPositionLevel() {
      this.loading = true;
      positionLevel({ is_list_tree: 1 })
        .then((res) => {
          const { data } = res;
          this.recombinationList(data);
          this.positionLevels = data;
          this.activeName = this.activeName ? this.activeName : data[0].id;
          this.positionList = [
            this.positionLevels.find((item) => item.id === this.activeName),
          ];
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 组织架构层级处理
    recombinationList(list) {
      list.forEach((el) => {
        el.tab_name = el.name;
        el.name = el.name + `(${el.level})`;
        if (el.children) {
          el.child = [...el.position, ...el.children];
          this.recombinationList(el.children);
        } else {
          el.child = [...el.position];
        }
      });
    },
    // 点击切换tab数据
    handleClick(e) {
      const positions = this.positionLevels.find((item) => item.id === e.name);
      this.positionList = [positions];
    },
    handleDragStart(draggingNode, ev) {
      console.log("ev", ev);
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log(
        "tree drag end: ",
        draggingNode.data.level_sort,
        dropNode.data.level_sort,
        dropType
      );

      if (dropType === "before") {
        const data = {
          organization_id: draggingNode.data.organization_id,
          position_id: draggingNode.data.id,
          level_sort:
            draggingNode.data.level_sort > dropNode.data.level_sort
              ? dropNode.data.level_sort
              : dropNode.data.level_sort - 1,
        };
        this.saveSort(data);
      }
      if (dropType === "after") {
        const data = {
          organization_id: draggingNode.data.organization_id,
          position_id: draggingNode.data.id,
          level_sort:
            draggingNode.data.level_sort > dropNode.data.level_sort
              ? dropNode.data.level_sort + 1
              : dropNode.data.level_sort,
        };
        this.saveSort(data);
      }
    },
    // 保存排序
    saveSort(data) {
      this.loading = true;
      savePositionLevel(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.getPositionLevel();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    allowDrag(draggingNode, dropNode, type) {
      return (
        draggingNode.data.level === undefined &&
        draggingNode.data.name !== "预备会员"
      );
    },
    allowDrop(draggingNode, dropNode, type) {
      return (
        draggingNode.data.organization_id === dropNode.data.organization_id &&
        ["next", "prev"].includes(type) &&
        dropNode.data.name !== "预备会员"
      );
    },
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tree-node {
  ::v-deep.is-dragging {
    background-color: red;
  }
}
</style>